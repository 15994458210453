"use client";

import { Logo } from "@/shared_components/Logo";

export default function MaintenancePage() {
  return (
    <div className="flex min-h-[calc(100vh-44px)] flex-col items-center justify-center bg-gray-100">
      <Logo position="center" />
      <h1 className="mb-4 mt-10 max-w-3xl text-3xl font-semibold tracking-tight">We&apos;ll be back.</h1>
      <p className="ml-4 text-xl text-gray-500">We&apos;re busy updating our agents for you and will be back soon.</p>
    </div>
  );
}
